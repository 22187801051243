import {
    COMMENTLOADING,
    UPLOAD,
    NOTUPLOAD,
    COMMENTLOADINGTRUE,
    COMMENTLOADINGFALSE,
    FAVORITELOADINGTRUE,
    FAVORITELOADINGFALSE,
    COMMFAVORITELOADINGTRUE,
    COMMFAVORITELOADINGFALSE,
    FOLLOWINGLOADINGTRUE,
    FOLLOWINGLOADINGFALSE,
    APPLYINGLOADINGTRUE,
    APPLYINGLOADINGFALSE,
    UPLOADSEARCH,
    SEARCHTOGGLETRUE,
    SEARCHTOGGLEFALSE,
    SEARCHKEYWORDTRUE,
    SEARCHKEYWORDFALSE,
    DRAWERTOGGLETRUE,
    DRAWERTOGGLEFALSE,
    UPLOADCONNECTINGTRUE,
    UPLOADCONNECTINGFALSE,
    DEVICELISTLOADINGTRUE,
    DEVICELISTLOADINGFALSE,
    DEVICEHOMELOADINGTRUE,
    DEVICEHOMELOADINGFALSE,
    DEVICEDBCONNECTOADINGTRUE,
    DEVICEDBCONNECTLOADINGFALSE
  } from './actionTypes'

  export const devicelistloadingtrue = (isDeviceListLoading) => (dispatch) => {
    return dispatch({
      type: DEVICELISTLOADINGTRUE,
      payload: {
        isDeviceListLoading,
      }
    })
  }

  export const devicelistloadingfalse = (isDeviceListLoading) => (dispatch) => {
    return dispatch({
      type: DEVICELISTLOADINGFALSE,
      payload: {
        isDeviceListLoading,
      }
    })
  }

  export const devicehomeloadingtrue = (isDeviceHomeLoading) => (dispatch) => {
    return dispatch({
      type: DEVICEHOMELOADINGTRUE,
      payload: {
        isDeviceHomeLoading,
      }
    })
  }

  export const devicehomeloadingfalse = (isDeviceHomeLoading) => (dispatch) => {
    return dispatch({
      type: DEVICEHOMELOADINGFALSE,
      payload: {
        isDeviceHomeLoading,
      }
    })
  }

  export const devicedbconnectloadingtrue = (isDeviceDBConnectLoading) => (dispatch) => {
    return dispatch({
      type: DEVICEDBCONNECTOADINGTRUE,
      payload: {
        isDeviceDBConnectLoading,
      }
    })
  }

  export const devicedbconnectloadingfalse = (isDeviceDBConnectLoading) => (dispatch) => {
    return dispatch({
      type: DEVICEDBCONNECTLOADINGFALSE,
      payload: {
        isDeviceDBConnectLoading,
      }
    })
  }

  export const commentloadingtrue = (isCommentLoading) => (dispatch) => {
    return dispatch({
      type: COMMENTLOADINGTRUE,
      payload: {
        isCommentLoading,
      },
    })
  }

  export const commentloadingfalse = (isCommentLoading) => (dispatch) => {
    return dispatch({
      type: COMMENTLOADINGFALSE,
      payload: {
        isCommentLoading,
      },
    })
  }

  export const favoriteloadingtrue = (isFavoriteLoading) => (dispatch) => {
    return dispatch({
      type: FAVORITELOADINGTRUE,
      payload: {
        isFavoriteLoading,
      },
    })
  }

  export const favoriteloadingfalse = (isFavoriteLoading) => (dispatch) => {
    return dispatch({
      type: FAVORITELOADINGFALSE,
      payload: {
        isFavoriteLoading,
      },
    })
  }

  export const commfavoriteloadingtrue = (isCommFavoriteLoading) => (dispatch) => {
    return dispatch({
      type: COMMFAVORITELOADINGTRUE,
      payload: {
        isCommFavoriteLoading,
      },
    })
  }

  export const commfavoriteloadingfalse = (isCommFavoriteLoading) => (dispatch) => {
    return dispatch({
      type: COMMFAVORITELOADINGFALSE,
      payload: {
        isCommFavoriteLoading,
      },
    })
  }

  export const followingloadingtrue = (isFollowingLoading) => (dispatch) => {
    return dispatch({
      type: FOLLOWINGLOADINGTRUE,
      payload: {
        isFollowingLoading,
      },
    })
  }

  export const followingloadingfalse = (isFollowingLoading) => (dispatch) => {
    return dispatch({
      type: FOLLOWINGLOADINGFALSE,
      payload: {
        isFollowingLoading,
      },
    })
  }

  export const applyingloadingtrue = (isApplyingLoading) => (dispatch) => {
    return dispatch({
      type: APPLYINGLOADINGTRUE,
      payload: {
        isApplyingLoading,
      },
    })
  }

  export const applyingloadingfalse = (isApplyingLoading) => (dispatch) => {
    return dispatch({
      type: APPLYINGLOADINGFALSE,
      payload: {
        isApplyingLoading,
      },
    })
  }

  export const uploadConnectingtrue = (isUploadConnecting) => (dispatch) => {
    return dispatch({
      type: UPLOADCONNECTINGTRUE,
      payload: {
        isUploadConnecting,
      },
    })
  }

  export const uploadConnectingfalse = (isUploadConnecting) => (dispatch) => {
    return dispatch({
      type: UPLOADCONNECTINGFALSE,
      payload: {
        isUploadConnecting,
      },
    })
  }

  export const uploadsearch = (searchKeyword) => (dispatch) => {
    return dispatch({
      type: UPLOADSEARCH,
      payload: {
        searchKeyword : searchKeyword
      }
    })
  }

  export const searchtoggletrue = (isSearchToggle) => (dispatch) => {
    return dispatch({
      type: SEARCHTOGGLETRUE,
      payload: {
        isSearchToggle,
      }
    })
  }

  export const searchtogglefalse = (isSearchToggle) => (dispatch) => {
    return dispatch({
      type: SEARCHTOGGLEFALSE,
      payload: {
        isSearchToggle,
      }
    })
  }

  export const searchkeywordtrue = (isSearchKeyword) => (dispatch) => {
    return dispatch({
      type: SEARCHKEYWORDTRUE,
      payload: {
        isSearchKeyword,
      }
    })
  }

  export const searchkeywordfalse = (isSearchKeyword) => (dispatch) => {
    return dispatch({
      type: SEARCHKEYWORDFALSE,
      payload: {
        isSearchKeyword,
      }
    })
  }

  export const drawertoggletrue = (isdrawerToggle) => (dispatch) => {
    return dispatch({
      type: DRAWERTOGGLETRUE,
      payload: {
        isdrawerToggle,
      }
    })
  }

  export const drawertogglefalse = (isdrawerToggle) => (dispatch) => {
    return dispatch({
      type: DRAWERTOGGLEFALSE,
      payload: {
        isdrawerToggle,
      }
    })
  }
  export const upload = () => (dispatch) => {
    return dispatch({
      type: UPLOAD,
      payload: {
        uploadPage : '/upload'
      }
    })
  }
  
  export const notupload = () => (dispatch) => {
    return dispatch({
      type: NOTUPLOAD,
    })
  }

  export const uploadcontents = (uploadContents) => (dispatch) => {
    return dispatch({
      type: UPLOADCONTENTS,
      payload: {
        uploadContents : uploadContents
      }
    })
  }