import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Feed_Home_Up from './Feed_Home_Up';
import Feed_Home from './Feed_Home';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// 달력
//import Calendar from 'react-calendar';
//import moment from 'moment';
import { TextField } from "@material-ui/core";

// select
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

// 체크박스
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

//import './UploadButton.scss' // UploadPhoto 버튼 관련

import axios from 'axios';
const apiURL = "https://api.lottodl.com";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(5),
    marginBottom : theme.spacing(12),
  },
  mainCard_1: {
    [theme.breakpoints.down('xs')]: {
      //display: 'none',
      width : '100%',
      // maxWidth: 650,
      // height: 450,
      maxHeight: 100,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      width : '100%',
      // maxWidth: 650,
      // height: 450,
      maxHeight: 100,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  mainCard_2: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 400,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_3: {
    // background: '#abbdff',
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_4: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 600,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_5: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 600,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_6: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 300,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  imageText: {
    fontSize: 100,
    color: '#FFFAFA', // snow
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '140px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '200px',
    },
    left: 0,
    width: '100%',
  },
  mainMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    //width : '100%',
    //height : 400,
    objectFit: 'fill'
  },
  cardFrame: {
    width : '100%',
    // maxWidth: 650,
    // height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  typoTitle1_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle1_2: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      color : '#FF0000',  // red
      fontSize : 20,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle1_3: {
    [theme.breakpoints.down('xs')]: {
      color : '#FF0000',  // red
      fontSize : 18,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  typoTitle2: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_3: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
  },
  typoTitle_4: {
    fontSize : 17,
  },
  typoTitle_5: {
    fontSize : 17,
  },
  typoTitle_5_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 17,
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 17,
    },
  },
  typoTitle_5_2: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 17,
      marginLeft : theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 17,
      display: 'none',
    },
  },
  typoTitle_6: {
    fontSize : 17,
  },
  typoTitle_7: {
    fontSize : 20,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(2),
    },
  },
  button1: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button2: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#A0A0A0',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  dialogButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  /*
  // 달력
  typoCalendar: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  textfieldCalendar: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '10px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldCalendarLabelSize:{
    fontSize:18
  },
  textFieldCalendarInputSize:{
    fontSize:18
  },
  calendar: {
    //marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  textFieldSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  */
  // select
  select_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  buttonChecked: {
    marginLeft: theme.spacing(1),
    color: '#0c93d0',
    '&$checked': {
      color: '#0c93d0',
    },
  },
});

class Home_Birth extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        alarmDialogOpen_1: false,
        completed : 0,
        lottoTurn : '',
        lottoTurnBirth : '', 
        lottoNumBirth_00 : [],
        lottoNumBirth_11 : [],
        lottoNumBirth_22 : [],
        lottoNumBirth_33 : [],
        lottoNumBirth_44 : [],
        lottoNumBirth_0 : [],
        lottoNumBirth_1 : [],
        lottoNumBirth_2 : [],
        lottoNumBirth_3 : [],
        lottoNumBirth_4 : [],
        alreadyGetLottoNumBirth: false,
        no_lottolistsBirth : '',
        alarmDialogOpen_1: false,
        alarmDialogOpen_2: false,
        alarmDialogOpen_3: false,
        // 달력 관련
        /*
        calendarOpen_1: false,
        dateOriginal_1: new Date(), // 오늘 날짜 (시작 캘린더용)
        dateStart: null, // 실제 선택한 일감 시작 날짜 (서버에 저장 날짜)
        */
        // select
        year: '',
        month: '',
        date: '',
        checked_1: true,
      };
  }
  
  // getData from flask with axios
  getLottoNumJason() {
    axios.get(`${apiURL}/getlotto`)
    .then(response => {
      const data = response.data;
      //console.log("JsonData: ", data);
      //console.log("lottoturn: ", data.lottoturn);
      // console.log("lottolists: ", data.lottolists);
      
      const lottoArray = data.lottolists;

      //console.log("last_lottoArray: ", last_lottoArray)
      //console.log("lottoArray: ", lottoArray)

      if (lottoArray == 'No lottolists') {
        this.setState({no_lottolistsBirth: 'no_lottolists'})
      }

      try {
        if (data.lottoturn) {
          const localSavedTurnBirth = localStorage.getItem("savedTurnBirth")

          if (localSavedTurnBirth == data.lottoturn) {
            localStorage.setItem("alreadyGetLottoNumBirth", "true");
            this.setState({alreadyGetLottoNumBirth: true})

            const localSavedLottoNumBirth_0 = localStorage.getItem("savedLottoNumBirth_0")
            const localSavedLottoNumBirth_1 = localStorage.getItem("savedLottoNumBirth_1")
            const localSavedLottoNumBirth_2 = localStorage.getItem("savedLottoNumBirth_2")
            const localSavedLottoNumBirth_3 = localStorage.getItem("savedLottoNumBirth_3")
            const localSavedLottoNumBirth_4 = localStorage.getItem("savedLottoNumBirth_4")

            const conSavedLottoNumBirth_0 = localSavedLottoNumBirth_0.split(',').map(Number);
            const conSavedLottoNumBirth_1 = localSavedLottoNumBirth_1.split(',').map(Number);
            const conSavedLottoNumBirth_2 = localSavedLottoNumBirth_2.split(',').map(Number);
            const conSavedLottoNumBirth_3 = localSavedLottoNumBirth_3.split(',').map(Number);
            const conSavedLottoNumBirth_4 = localSavedLottoNumBirth_4.split(',').map(Number);

            this.setState({ lottoTurnBirth: localSavedTurnBirth, lottoNumBirth_0: conSavedLottoNumBirth_0, lottoNumBirth_1: conSavedLottoNumBirth_1,
              lottoNumBirth_2: conSavedLottoNumBirth_2, lottoNumBirth_3: conSavedLottoNumBirth_3, lottoNumBirth_4: conSavedLottoNumBirth_4});
          }
          if (localSavedTurnBirth != data.lottoturn) {
            localStorage.setItem("alreadyGetLottoNumBirth", "false");
            this.setState({alreadyGetLottoNumBirth: false})

            this.setState({lottoNumBirth_0: [], lottoNumBirth_1: [], lottoNumBirth_2: [], lottoNumBirth_3: [], lottoNumBirth_4: []})
            /*
            this.setState({ lottoTurnBirth: data.lottoturn, lottoNumBirth_00: lottoArrayBirth[0], lottoNumBirth_11: lottoArrayBirth[1],
              lottoNumBirth_22: lottoArrayBirth[2], lottoNumBirth_33: lottoArrayBirth[3], lottoNumBirth_44: lottoArrayBirth[4]});
            */
          }
        }
      } catch(e) {
        if (data.lottoturn) {
          localStorage.setItem("alreadyGetLottoNumBirth", "false");
          this.setState({alreadyGetLottoNumBirth: false})

          this.setState({lottoNumBirth_0: [], lottoNumBirth_1: [], lottoNumBirth_2: [], lottoNumBirth_3: [], lottoNumBirth_4: []})
          /*
          this.setState({ lottoTurnBirth: data.lottoturn, lottoNumBirth_00: lottoArrayBirth[0], lottoNumBirth_11: lottoArrayBirth[1],
            lottoNumBirth_22: lottoArrayBirth[2], lottoNumBirth_33: lottoArrayBirth[3], lottoNumBirth_44: lottoArrayBirth[4]});
          */
        }
        // console.log("savedTurn error")
      }

      /*
      this.setState({ lottoTurn: data.lottoturn, lottoNum_0: lottoArray[0], lottoNum_1: lottoArray[1],
         lottoNum_2: lottoArray[2]});
      */
      // this.setState({lottoNum_3: lottoArray[3], lottoNum_4: lottoArray[4],});
      // console.log("last_lotto: ", this.state.last_lotto);
      //console.log("lottoNum_0: ", this.state.lottoNum_0);
    })
    .catch(error => {
      console.error(error);
    });
  }

  numberWithCommas = (number) => {
    const numberString = number.toString();
    const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
  };

  handleGetLotto = async event => {
    event.preventDefault();

    if (this.state.no_lottolistsBirth == 'no_lottolists') {
      this.setState({alarmDialogOpen_1 : true})
    }

    // 생일 날짜 저장 안되어 있으면 알림
    /*
    if (!this.state.dateStart) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }
    */

    // 생일 날짜 저장 안되어 있으면 알림
    if (!this.state.year) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }

    // 생일 날짜 저장 안되어 있으면 알림
    if (!this.state.month) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }

    // 생일 날짜 저장 안되어 있으면 알림
    if (!this.state.date) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }

    if (!this.state.checked_1) {
      this.setState({alarmDialogOpen_3: true});
      return;
    }

    /*
    console.log(moment(this.state.dateStart).format("YYYY-MM-DD"))
    console.log(moment(this.state.dateStart).format("YYYY" + "년"))
    console.log(moment(this.state.dateStart).format("MM" + "월"))
    console.log(moment(this.state.dateStart).format("DD" + "일"))
    */

    // calender
    // localStorage.setItem("dateStart", this.state.dateStart);

    /*
    const year = moment(this.state.dateStart).format("YYYY")
    const month = moment(this.state.dateStart).format("MM")
    const date = moment(this.state.dateStart).format("DD")
    */

    localStorage.setItem("year", this.state.year);
    localStorage.setItem("month", this.state.month);
    localStorage.setItem("date", this.state.date);

    const birth = this.state.year.toString() + this.state.month.toString() + this.state.date.toString();
    // console.log("birth: ", birth)

    const postdata = {
      birthday: birth
    };
    // console.log("data: ", data)
    
    axios.post(`${apiURL}/postbirth`, postdata)
      .then(response => {
      const data = response.data;
      //console.log("JsonData: ", data);
      //console.log("lottoturn: ", data.lottoturn);
      //console.log("lottolists: ", data.lottolists);
      
      const lottoArrayBirth = data.lottolists;
      //console.log("last_lottoArray: ", last_lottoArray)
      //console.log("lottoArray: ", lottoArray)
      if (lottoArrayBirth == 'No lottolists') {
        this.setState({no_lottolistsBirth: 'no_lottolists'})
      }

      // lottoTrun 정보 있으면 실행
      if (data.lottoturn) {
        try {
          const localAlreadyGetLottoNumBirth = localStorage.getItem("alreadyGetLottoNumBirth")

          if (localAlreadyGetLottoNumBirth == "true") {
            this.setState({alreadyGetLottoNumBirth: true})
          }
          if (localAlreadyGetLottoNumBirth == "false") {  
            localStorage.setItem("savedTurnBirth", data.lottoturn);
            localStorage.setItem("savedLottoNumBirth_0", lottoArrayBirth[0]);
            localStorage.setItem("savedLottoNumBirth_1", lottoArrayBirth[1]);
            localStorage.setItem("savedLottoNumBirth_2", lottoArrayBirth[2]);
            localStorage.setItem("savedLottoNumBirth_3", lottoArrayBirth[3]);
            localStorage.setItem("savedLottoNumBirth_4", lottoArrayBirth[4]);

            this.setState({ lottoTurnBirth: data.lottoturn, lottoNumBirth_0: lottoArrayBirth[0], lottoNumBirth_1: lottoArrayBirth[1],
              lottoNumBirth_2: lottoArrayBirth[2], lottoNumBirth_3: lottoArrayBirth[3], lottoNumBirth_4: lottoArrayBirth[4]});
            
            localStorage.setItem("alreadyGetLottoNumBirth", "true");
            this.setState({alreadyGetLottoNumBirth: true})
          }
        } catch(e){
          console.log("alreadyGetLottoNumBirth error")
        }
      }
    })
    
    // 네트워크 연결 안되어서 정보 못받아 오면 버튼 눌러도 그냥 false 처리
    if (!this.state.lottoTurnBirth) {
      this.setState({alreadyGetLottoNumBirth: false})
    }
  }

  /*
  handleNotification() {
    const title = '로또딥러닝'
    const body = '로또 대박 나세요.'
    const url = 'https://www.lottodl.com/birth'
    const postdata = {
      title:title,
      body:body,
      data: {
        url:url
      },
    };
  
    console.log("postdata: ", postdata)
  
    axios.post(`${apiURL}/notification`, postdata)
      .then(response => {
        const data = response.data;
        console.log('Notification data:', data);
      })
      .catch(error => {
        console.error('Error posting notification:', error);
      });
  }
  */
  

  /*
  // select
  handleNumberChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    // The regular expression /[^0-9]/g will replace any non-numeric character with an empty string

    const numericValue_string = numericValue.toString();
    const numericValue_substring = numericValue_string.substring(0, 8);
    e.target.value = numericValue_substring; // Set the input value to the numeric value

    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };
  */

  handleChangeYear = (event) => {
    this.setState({ year: event.target.value });
  };

  handleChangeMonth = (event) => {
    this.setState({ month: event.target.value });
  };

  handleChangeDate = (event) => {
    this.setState({ date: event.target.value });
  };

  /*
  // 달력
  handleCalendarOpen_1 = () => {
    if(this.state.calendarOpen_1 === false) {
      this.setState({calendarOpen_1: true})
    } else if (this.state.calendarOpen_1 === true) {
      this.setState({calendarOpen_1: false})
    }
  }

  onDateChange_1 = dateOriginal_1 => { 
    this.setState({
      dateOriginal_1,
      dateStart : dateOriginal_1,
      calendarOpen_1: false,
    })

    /
    console.log(moment(this.state.dateStart).format("YYYY-MM-DD"))
    console.log(moment(this.state.dateStart).format("YYYY" + "년"))
    console.log(moment(this.state.dateStart).format("MM" + "월"))
    console.log(moment(this.state.dateStart).format("DD" + "일"))
    /
  }
  */

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;

    // kakao adfit
    let ins = document.createElement('ins');
    let scr = document.createElement('script');
    ins.className = 'kakao_ad_area';
    ins.style = "display:none;";
    scr.async = 'true';
    scr.type = "text/javascript";
    scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    ins.setAttribute('data-ad-width', '320');
    ins.setAttribute('data-ad-height', '100');
    ins.setAttribute('data-ad-unit', 'DAN-UTb4DXxhAXF8oGBW');
    document.querySelector('.adfit').appendChild(ins);
    document.querySelector('.adfit').appendChild(scr);
    ///

    await this.getLottoNumJason();

    /*
    const localdateStart = localStorage.getItem("dateStart")
    this.setState({dateStart: localdateStart})
    */

    try {
      const localYear = localStorage.getItem("year")
      if (localYear) {
       this.setState({year: localYear})
      }
     
      const localMonth = localStorage.getItem("month")
      if(localMonth) {
       this.setState({month: localMonth})
      }
      
      const localDate = localStorage.getItem("date")
      if(localDate) {
       this.setState({date: localDate})
     }
    } catch(e) {
      console.log(e)
    }

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handleCheckedChange = name => event => {
    this.setState({ [name] : event.target.checked });
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
    })
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, } = this.props;
    // const { dateOriginal_1, dateStart } = this.state

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card className={classes.mainCard_1}>
                <div className="adfit"></div>
                {/*
                <CardMedia
                  className={classes.mainMedia}
                  // image={'/images/main/bg_home.jpg'}  // 테스트시 사용
                  image={'/images/main/bg_deeplearning.png'}  // 테스트시 사용
                  // image={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                />
                */}
              </Card>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Card className={classes.mainCard_2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> 
                <Typography className={classes.typoTitle1_1}>
                  생일을 입력하고 행운 받으세요
                </Typography>
              </Grid>
              {/*
              <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                <Card className={classes.mainCard_6}>
                  <TextField
                    className={classes.textField}
                    id="year"
                    name="year"
                    //type="number"
                    autoFocus
                    value={this.state.year}
                    onChange={this.handleNumberChange}
                    //placeholder="숫자만 입력 바랍니다."
                    autoComplete="off"
                    InputProps={{
                      classes: {
                        input: classes.textFieldSize,
                      },
                    }}
                  />
              </Card>
             </Grid>
              */}
              <Grid container justify="center" alignItems="flex-start" align="flex-start" spacing={2}>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">year</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.year}
                        label="year"
                        onChange={this.handleChangeYear}
                      >
                        <MenuItem value={1945}>1945</MenuItem>
                        <MenuItem value={1946}>1946</MenuItem>
                        <MenuItem value={1947}>1947</MenuItem>
                        <MenuItem value={1948}>1948</MenuItem>
                        <MenuItem value={1949}>1949</MenuItem>
                        <MenuItem value={1950}>1950</MenuItem>
                        <MenuItem value={1951}>1951</MenuItem>
                        <MenuItem value={1952}>1952</MenuItem>
                        <MenuItem value={1953}>1953</MenuItem>
                        <MenuItem value={1954}>1954</MenuItem>
                        <MenuItem value={1955}>1955</MenuItem>
                        <MenuItem value={1956}>1956</MenuItem>
                        <MenuItem value={1957}>1957</MenuItem>
                        <MenuItem value={1958}>1958</MenuItem>
                        <MenuItem value={1959}>1959</MenuItem>
                        <MenuItem value={1960}>1960</MenuItem>
                        <MenuItem value={1961}>1961</MenuItem>
                        <MenuItem value={1962}>1962</MenuItem>
                        <MenuItem value={1963}>1963</MenuItem>
                        <MenuItem value={1964}>1964</MenuItem>
                        <MenuItem value={1965}>1965</MenuItem>
                        <MenuItem value={1966}>1966</MenuItem>
                        <MenuItem value={1967}>1967</MenuItem>
                        <MenuItem value={1968}>1968</MenuItem>
                        <MenuItem value={1969}>1969</MenuItem>
                        <MenuItem value={1970}>1970</MenuItem>
                        <MenuItem value={1971}>1971</MenuItem>
                        <MenuItem value={1972}>1972</MenuItem>
                        <MenuItem value={1973}>1973</MenuItem>
                        <MenuItem value={1974}>1974</MenuItem>
                        <MenuItem value={1975}>1975</MenuItem>
                        <MenuItem value={1976}>1976</MenuItem>
                        <MenuItem value={1977}>1977</MenuItem>
                        <MenuItem value={1978}>1978</MenuItem>
                        <MenuItem value={1979}>1979</MenuItem>
                        <MenuItem value={1980}>1980</MenuItem>
                        <MenuItem value={1981}>1981</MenuItem>
                        <MenuItem value={1982}>1982</MenuItem>
                        <MenuItem value={1983}>1983</MenuItem>
                        <MenuItem value={1984}>1984</MenuItem>
                        <MenuItem value={1985}>1985</MenuItem>
                        <MenuItem value={1986}>1986</MenuItem>
                        <MenuItem value={1987}>1987</MenuItem>
                        <MenuItem value={1988}>1988</MenuItem>
                        <MenuItem value={1989}>1989</MenuItem>
                        <MenuItem value={1990}>1990</MenuItem>
                        <MenuItem value={1991}>1991</MenuItem>
                        <MenuItem value={1992}>1992</MenuItem>
                        <MenuItem value={1993}>1993</MenuItem>
                        <MenuItem value={1994}>1994</MenuItem>
                        <MenuItem value={1995}>1995</MenuItem>
                        <MenuItem value={1996}>1996</MenuItem>
                        <MenuItem value={1997}>1997</MenuItem>
                        <MenuItem value={1998}>1998</MenuItem>
                        <MenuItem value={1999}>1999</MenuItem>
                        <MenuItem value={2000}>2000</MenuItem>
                        <MenuItem value={2001}>2001</MenuItem>
                        <MenuItem value={2002}>2002</MenuItem>
                        <MenuItem value={2003}>2003</MenuItem>
                        <MenuItem value={2004}>2004</MenuItem>
                        <MenuItem value={2005}>2005</MenuItem>
                        <MenuItem value={2006}>2006</MenuItem>
                        <MenuItem value={2007}>2007</MenuItem>
                        <MenuItem value={2008}>2008</MenuItem>
                        <MenuItem value={2009}>2009</MenuItem>
                        <MenuItem value={2010}>2010</MenuItem>
                        <MenuItem value={2011}>2011</MenuItem>
                        <MenuItem value={2012}>2012</MenuItem>
                        <MenuItem value={2013}>2013</MenuItem>
                        <MenuItem value={2014}>2014</MenuItem>
                        <MenuItem value={2015}>2015</MenuItem>
                        <MenuItem value={2016}>2016</MenuItem>
                        <MenuItem value={2017}>2017</MenuItem>
                        <MenuItem value={2018}>2018</MenuItem>
                        <MenuItem value={2019}>2019</MenuItem>
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                {/*
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align="flex-start">
                  <Typography className={classes.typoTitle_7}>
                    년
                  </Typography>
                </Grid>
                */}
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">month</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select2"
                        value={this.state.month}
                        label="month"
                        onChange={this.handleChangeMonth}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                {/*
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align="flex-start">
                  <Typography className={classes.typoTitle_7}>
                    월
                  </Typography>
                </Grid>
                */}
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">date</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select3"
                        value={this.state.date}
                        label="date"
                        onChange={this.handleChangeDate}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                {/*
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align="flex-start">
                  <Typography className={classes.typoTitle_7}>
                    일
                  </Typography>
                </Grid>
                */}
              </Grid>
              {/*
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                  <Typography style={{ cursor: 'pointer' }} component={'span'} className={classes.typoCalendar} onClick={this.handleCalendarOpen_1}>
                  { this.state.dateStart
                  ?
                  <TextField className={classes.textfieldCalendar}
                    InputLabelProps={{
                      classes: {
                        root: classes.textFieldCalendarLabelSize
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.textFieldCalendarInputSize,
                      },
                      }}
                    /label="생일 입력"/ type="dateStart" name="dateStart" value={moment(dateStart).format("YYYY-MM-DD") || ''} 
                    autoComplete="off"
                  />
                  :
                  <TextField className={classes.textfieldCalendar}
                    InputLabelProps={{
                      classes: {
                        root: classes.textFieldCalendarLabelSize
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.textFieldCalendarInputSize,
                      },
                      }}
                    label="생일 입력" type="dateStart" name="dateStart" value={undefined || ''} autoComplete="off"
                  />
                  }
                  </Typography>
                  {/
                  <Typography className={classes.typoTitle1_2}>
                    {'사주 : '}
                  </Typography>
                  /}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {this.state.calendarOpen_1
                  ?
                  <Calendar
                    className={classes.calendar}
                    onChange={this.onDateChange_1}
                    value={dateOriginal_1}
                    />
                  :
                  null
                  }
                  {/
                  <Typography className={classes.typoTitle1_2}>
                    {'사주 : '}
                  </Typography>
                  /}
                </Grid>
              </Grid>
              */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className={classes.typoTitle1_2}>
                    기존에 분석된 인공지능에 생일 정보 추가 조합
                  </Typography>
                  <Typography className={classes.typoTitle1_3}>
                    기존에 분석된 인공지능에
                  </Typography>
                  <Typography className={classes.typoTitle1_3} display="inline">
                    생일 정보 추가 조합
                  </Typography>
                </Grid>
              </Grid>
            </Card>        
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Card className={classes.mainCard_3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {this.state.no_lottolistsBirth == 'no_lottolists'
                    ?
                    <Typography className={classes.typoTitle2}>
                      다음 예측 번호를 분석중입니다...
                    </Typography>
                    :
                    <Typography className={classes.typoTitle2}>
                      {this.state.lottoTurnBirth} {' 회차 예상번호'}
                    </Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNumBirth_0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNumBirth_1}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNumBirth_2}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNumBirth_3}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNumBirth_4}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                {this.state.alreadyGetLottoNumBirth
                ?
                <Button variant="contained" /*color="primary"*/ className={classes.button2}>
                  <Typography className={classes.typoTitle_4}>다음회차때 받기</Typography>
                </Button>
                :
                <Button variant="contained" /*color="primary"*/ className={classes.button1}
                onClick={this.handleGetLotto}>
                  <Typography className={classes.typoTitle_4}>무료 로또 예측번호 받기</Typography>
                </Button>
                }
              </Grid>
              {/*
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button1}
                onClick={this.handleNotification}>
                  <Typography className={classes.typoTitle_4}>notification test</Typography>
                </Button>
              </Grid>
              */}
              </Card>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={0}>
            <Card className={classes.mainCard_5}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox color="default" className={classes.buttonChecked} checked={this.state.checked_1} 
                    onChange={this.handleCheckedChange('checked_1')} name="necessary" />
                  }
                  label="개인정보 수집 및 활용동의(필수)"
                /> 
                <Link component={RouterLink} to={`/infoagreememnt`} underline='none' color='inherit'>
                  <Typography className={classes.typoTitle_5_1} display="inline">
                    [보기]
                  </Typography>
                  <Typography className={classes.typoTitle_5_2}>
                    [보기]
                  </Typography>
                </Link>
              </Grid>
            </Card>
          </Grid>
          {/*
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={0}>
            <Card className={classes.mainCard_4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
                <Typography className={classes.typoTitle_3}>
                  {'· 무료 버전: 일주일에 한번 5줄의 번호 분석'}
                </Typography>
                <Typography className={classes.typoTitle_3}>
                  {'· 매주 토요일 로또 추첨 이후 인공지능 딥러닝 분석 완료 후, 그다음 회차의 로또 예측 번호를 받으실 수 있습니다.'}
                </Typography>
              </Grid>
              <Divider/>
            </Card>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={0}>
            <Card className={classes.mainCard_4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
              </Grid>
              <Divider/>
            </Card>
          </Grid>
          */}
          {/*
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>카이스컴퍼니</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>사업자등록번호 : 229-34-00829</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>대표 : 김재영</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>고객센터</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>043)714-3378</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>lootohub@gmail.com</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent3}>평일 09:00 ~ 18:00 / 주말·공휴일 휴무</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          */}
        </Grid>
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>
          <Typography className={classes.typoTitle_5}>다음 예측 번호를 분석중입니다.</Typography>
          <Typography className={classes.typoTitle_5}>잠시만 기다려 주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_6}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2}>
        <DialogTitle>
          <Typography className={classes.typoTitle_5}>생일을 입력해주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_6}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_3}>
        <DialogTitle>
          <Typography className={classes.typoTitle_5}>개인정보 수집 및 활용동의(필수)에 체크해주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_6}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

Home_Birth = withStyles(styles, {withTheme: true})(Home_Birth)
export default connect(mapStateToProps, mapDispatchToProps)(Home_Birth)