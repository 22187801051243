//import { cav } from 'klaytn/caver'
import {
  KLAYTNLOGIN,
  KLAYTNLOGOUT,
  INTEGRATE_WALLET,
  REMOVE_WALLET,
  //AWSLOGIN,
  //AWSLOGOUT,
  USERHASAUTHENTICATEDTRUE,
  USERHASAUTHENTICATEDFALSE,
  CURRENTAUTHENTICATEDUSER,
  SWREGISTRATION,
  // AWSCOGNITOON,
} from './actionTypes'

export const integrateWallet = (privateKey) => (dispatch) => {
  const walletInstance = cav.klay.accounts.privateKeyToAccount(privateKey)
  cav.klay.accounts.wallet.add(walletInstance)
  sessionStorage.setItem('walletInstance', JSON.stringify(walletInstance))
  return dispatch({
    type: INTEGRATE_WALLET,
    payload: {
      privateKey,
      address: walletInstance.address,
    },
  })
}

export const removeWallet = () => (dispatch) => {
  cav.klay.accounts.wallet.clear()
  sessionStorage.removeItem('walletInstance')
  return dispatch({
    type: REMOVE_WALLET,
  })
}

export const klaytnlogin = (privateKey) => (dispatch) => {
  dispatch(integrateWallet(privateKey))
  return dispatch({
    type: KLAYTNLOGIN,
  })
}

export const klaytnlogout = () => (dispatch) => {
  dispatch(removeWallet())
  return dispatch({
    type: KLAYTNLOGOUT,
  })
}

//-------------------------------------------------------------------
// AWS
//-------------------------------------------------------------------
/*
export const awsCognitoOn = (email, password) => (dispatch) => {
  try {
    Auth.signIn(email, password);
    alert("Logged in"); // 로그인 하면 로그인 됬다는 창 띄워준다.
  } catch (e) {
    alert(e.message);
  }
  sessionStorage.setItem('emailInstance', JSON.stringify(email))
  return dispatch({
    type: AWSCOGNITOON,
    payload: {
      email: email,
      password: password,
    },
  })
}
*/
/*
export const awslogin = () => (dispatch) => {
  // dispatch(awsCognitoOn(email, password))
  return dispatch({
    type: AWSLOGIN,
  })
}

export const awslogout = () => (dispatch) => {
  return dispatch({
    type: AWSLOGOUT,
  })
}
*/

export const userHasAuthenticatedTrue = () => (dispatch) => {
  return dispatch({
    type: USERHASAUTHENTICATEDTRUE,
  })
}

export const userHasAuthenticatedFalse = () => (dispatch) => {
  return dispatch({
    type: USERHASAUTHENTICATEDFALSE,
  })
}

export const currentAuthUser = (currentUser) => (dispatch) => {
  return dispatch({
    type: CURRENTAUTHENTICATEDUSER,
    payload: {
      currentUser,
    },
  })
}

export const swRegistration = (isSwRegistration) => (dispatch) => {
  return dispatch({
    type: SWREGISTRATION,
    payload: {
      isSwRegistration,
    },
  })
}