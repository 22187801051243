import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

import Feed_Home_Fath from './Feed_Home_Fath';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// 달력
//import Calendar from 'react-calendar';
//import moment from 'moment';
import { TextField } from "@material-ui/core";

// select
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

// 체크박스
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

//import './UploadButton.scss' // UploadPhoto 버튼 관련

import axios from 'axios';
const apiURL = "https://api.lottodl.com";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(5),
    marginBottom : theme.spacing(12),
  },
  mainCard_1: {
    [theme.breakpoints.down('xs')]: {
      //display: 'none',
      width : '100%',
      // maxWidth: 650,
      // height: 450,
      maxHeight: 100,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      width : '100%',
      // maxWidth: 650,
      // height: 450,
      maxHeight: 100,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  mainCard_2: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 400,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_3: {
    // background: '#abbdff',
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    //maxHeight: 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_4: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 600,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_5: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 600,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_6: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 300,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  imageText: {
    fontSize: 100,
    color: '#FFFAFA', // snow
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '140px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '200px',
    },
    left: 0,
    width: '100%',
  },
  mainMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    //width : '100%',
    //height : 400,
    objectFit: 'fill'
  },
  cardFrame: {
    width : '100%',
    // maxWidth: 650,
    // height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  typoTitle1_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle1_2: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      color : '#FF0000',  // red
      fontSize : 20,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle1_3: {
    [theme.breakpoints.down('xs')]: {
      color : '#FF0000',  // red
      fontSize : 18,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  typoTitle2: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_3: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
  },
  typoTitle_4: {
    fontSize : 17,
  },
  typoTitle_5: {
    fontSize : 17,
  },
  typoTitle_5_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 17,
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 17,
    },
  },
  typoTitle_5_2: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 17,
      marginLeft : theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 17,
      display: 'none',
    },
  },
  typoTitle_6: {
    fontSize : 17,
  },
  typoTitle_7: {
    fontSize : 20,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(2),
    },
  },
  button1: {
    marginBottom: theme.spacing(2),
    marginRight : theme.spacing(1),
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button2: {
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(1),
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  dialogButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  // select
  select_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  buttonChecked: {
    marginLeft: theme.spacing(1),
    color: '#0c93d0',
    '&$checked': {
      color: '#0c93d0',
    },
  },
  progress: {
    position: 'fixed',
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

// 슬라이딩 윈도우 기법
class Home_Fath extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        alarmDialogOpen_1: false,
        completed : 0,
        lottoTurn : '',
        // select
        select_1: '',
        select_2: '',
        select_3: '',
        select_4: '',
        select_5: '',
        select_6: '',
        select_7: '',
        select_8: '',
        select_9: '',
        select_10: '',
        select_11: '',
        select_12: '',
        select_13: '',
        select_14: '',
        select_15: '',
        result : [],
        completed : 0,
        alarmDialogOpen_1: false,
        alarmDialogOpen_2: false,
        alarmDialogOpen_3: false,
      };
  }

  // getData from flask with axios
  getLottoNumJason() {
    axios.get(`${apiURL}/getlotto`)
      .then(response => {
        const data = response.data;
        //console.log("JsonData: ", data);
        //console.log("lottoturn: ", data.lottoturn);
        this.setState({lottoTurn: data.lottoturn})
      })
  }

  handleClearLotto= async event => {
    event.preventDefault();

    this.setState({select_1: '', select_2: '', select_3: '', select_4: '', select_5: '',
    select_6: '', select_7: '', select_8: '', select_9: '', select_10: '', select_11: '',
    select_12: '', select_13: '', select_14: '', select_15: '', /*result : []*/})

    localStorage.setItem("select_1", this.state.select_1);
    localStorage.setItem("select_2", this.state.select_2);
    localStorage.setItem("select_3", this.state.select_3);
    localStorage.setItem("select_4", this.state.select_4);
    localStorage.setItem("select_5", this.state.select_5);
    localStorage.setItem("select_6", this.state.select_6);
    localStorage.setItem("select_7", this.state.select_7);
    localStorage.setItem("select_8", this.state.select_8);
    localStorage.setItem("select_9", this.state.select_9);
    localStorage.setItem("select_10", this.state.select_10);
    localStorage.setItem("select_11", this.state.select_11);
    localStorage.setItem("select_12", this.state.select_12);
    localStorage.setItem("select_13", this.state.select_13);
    localStorage.setItem("select_14", this.state.select_14);
    localStorage.setItem("select_15", this.state.select_15);

    //localStorage.setItem('result', this.state.result)
  }

  handleGetLotto = async event => {
    event.preventDefault();

    localStorage.setItem("select_1", this.state.select_1);
    localStorage.setItem("select_2", this.state.select_2);
    localStorage.setItem("select_3", this.state.select_3);
    localStorage.setItem("select_4", this.state.select_4);
    localStorage.setItem("select_5", this.state.select_5);
    localStorage.setItem("select_6", this.state.select_6);
    localStorage.setItem("select_7", this.state.select_7);
    localStorage.setItem("select_8", this.state.select_8);
    localStorage.setItem("select_9", this.state.select_9);
    localStorage.setItem("select_10", this.state.select_10);
    localStorage.setItem("select_11", this.state.select_11);
    localStorage.setItem("select_12", this.state.select_12);
    localStorage.setItem("select_13", this.state.select_13);
    localStorage.setItem("select_14", this.state.select_14);
    localStorage.setItem("select_15", this.state.select_15);

    // initialize an empty array
    let selectList = [];
    
    // loop through the state variables
    for (let i = 1; i <= 15; i++) {
        let currentVal = this.state['select_' + i];
        if (currentVal && !selectList.includes(currentVal)) {
            // if value is not null/empty and not a duplicate, append to list
            selectList.push(currentVal);
        }
    }

    let selectSortSorted = selectList.sort((a, b) => parseInt(a) - parseInt(b));
    console.log("selectSortSorted: ", selectSortSorted);

    // initialize an empty array to store all combinations
    let combinations = [];

    // generate all possible combinations of length 6
    for (let i = 0; i < selectSortSorted.length - 5; i++) {
      for (let j = i + 1; j < selectSortSorted.length - 4; j++) {
        for (let k = j + 1; k < selectSortSorted.length - 3; k++) {
          for (let l = k + 1; l < selectSortSorted.length - 2; l++) {
            for (let m = l + 1; m < selectSortSorted.length - 1; m++) {
              for (let n = m + 1; n < selectSortSorted.length; n++) {
                const combination = [              selectSortSorted[i],
                  selectSortSorted[j],
                  selectSortSorted[k],
                  selectSortSorted[l],
                  selectSortSorted[m],
                  selectSortSorted[n]
                ];
                combinations.push(combination);
              }
            }
          }
        }
      }
    }
    this.setState({ result : combinations});
    //localStorage.setItem('result', combinations)
    //console.log(combinations);
  }

  

  handleChange1 = (event) => {
    this.setState({ select_1: event.target.value });
  };
  handleChange2 = (event) => {
    this.setState({ select_2: event.target.value });
  };
  handleChange3 = (event) => {
    this.setState({ select_3: event.target.value });
  };
  handleChange4 = (event) => {
    this.setState({ select_4: event.target.value });
  };
  handleChange5 = (event) => {
    this.setState({ select_5: event.target.value });
  };
  handleChange6 = (event) => {
    this.setState({ select_6: event.target.value });
  };
  handleChange7 = (event) => {
    this.setState({ select_7: event.target.value });
  };
  handleChange8 = (event) => {
    this.setState({ select_8: event.target.value });
  };
  handleChange9 = (event) => {
    this.setState({ select_9: event.target.value });
  };
  handleChange10 = (event) => {
    this.setState({ select_10: event.target.value });
  };
  handleChange11 = (event) => {
    this.setState({ select_11: event.target.value });
  };
  handleChange12 = (event) => {
    this.setState({ select_12: event.target.value });
  };
  handleChange13 = (event) => {
    this.setState({ select_13: event.target.value });
  };
  handleChange14 = (event) => {
    this.setState({ select_14: event.target.value });
  };
  handleChange15 = (event) => {
    this.setState({ select_15: event.target.value });
  };

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;

    // kakao adfit
    let ins = document.createElement('ins');
    let scr = document.createElement('script');
    ins.className = 'kakao_ad_area';
    ins.style = "display:none;";
    scr.async = 'true';
    scr.type = "text/javascript";
    scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    ins.setAttribute('data-ad-width', '320');
    ins.setAttribute('data-ad-height', '100');
    ins.setAttribute('data-ad-unit', 'DAN-UTb4DXxhAXF8oGBW');
    document.querySelector('.adfit').appendChild(ins);
    document.querySelector('.adfit').appendChild(scr);
    ///

    await this.getLottoNumJason();

    try {
      this.state.select_1 = localStorage.getItem("select_1")
      this.state.select_2 = localStorage.getItem("select_2")
      this.state.select_3 = localStorage.getItem("select_3")
      this.state.select_4 = localStorage.getItem("select_4")
      this.state.select_5 = localStorage.getItem("select_5")
      this.state.select_6 = localStorage.getItem("select_6")
      this.state.select_7 = localStorage.getItem("select_7")
      this.state.select_8 = localStorage.getItem("select_8")
      this.state.select_9 = localStorage.getItem("select_9")
      this.state.select_10 = localStorage.getItem("select_10")
      this.state.select_11 = localStorage.getItem("select_11")
      this.state.select_12 = localStorage.getItem("select_12")
      this.state.select_13 = localStorage.getItem("select_13")
      this.state.select_14= localStorage.getItem("select_14")
      this.state.select_15 = localStorage.getItem("select_15")

      //this.state.result = localStorage.getItem('result')
    } catch(e) {
      
    }

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handleCheckedChange = name => event => {
    this.setState({ [name] : event.target.checked });
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
    })
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, } = this.props;
    //const { result } = this.state;
    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card className={classes.mainCard_1}>
                <div className="adfit"></div>
              </Card>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Card className={classes.mainCard_2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> 
                <Typography className={classes.typoTitle1_1}>
                  번호를 입력하고 행운 받으세요
                </Typography>
              </Grid>
              <Grid container justify="center" alignItems="flex-start" align="flex-start" spacing={2}>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자1</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_1}
                        label="select1"
                        onChange={this.handleChange1}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자2</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_2}
                        label="select1"
                        onChange={this.handleChange2}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자3</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_3}
                        label="select1"
                        onChange={this.handleChange3}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자4</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_4}
                        label="select1"
                        onChange={this.handleChange4}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자5</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_5}
                        label="select1"
                        onChange={this.handleChange5}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자6</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_6}
                        label="select1"
                        onChange={this.handleChange6}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자7</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_7}
                        label="select1"
                        onChange={this.handleChange7}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자8</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_8}
                        label="select1"
                        onChange={this.handleChange8}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자9</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_9}
                        label="select1"
                        onChange={this.handleChange9}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자10</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_10}
                        label="select1"
                        onChange={this.handleChange10}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자11</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_11}
                        label="select1"
                        onChange={this.handleChange11}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자12</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_12}
                        label="select1"
                        onChange={this.handleChange12}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자13</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_13}
                        label="select1"
                        onChange={this.handleChange13}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자14</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_14}
                        label="select1"
                        onChange={this.handleChange14}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
                  <Card className={classes.mainCard_6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">숫자15</InputLabel>
                      <Select
                        className={classes.select_1}
                        /*labelId="select-label"*/
                        id="select1"
                        value={this.state.select_15}
                        label="select1"
                        onChange={this.handleChange15}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={33}>33</MenuItem>
                        <MenuItem value={34}>34</MenuItem>
                        <MenuItem value={35}>35</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={37}>37</MenuItem>
                        <MenuItem value={38}>38</MenuItem>
                        <MenuItem value={39}>39</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={41}>41</MenuItem>
                        <MenuItem value={42}>42</MenuItem>
                        <MenuItem value={43}>43</MenuItem>
                        <MenuItem value={44}>44</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Card>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button1}
                onClick={this.handleGetLotto}>
                  <Typography className={classes.typoTitle_4}>로또 번호 받기</Typography>
                </Button>
                <Button variant="contained" /*color="primary"*/ className={classes.button2}
                onClick={this.handleClearLotto}>
                  <Typography className={classes.typoTitle_4}>번호 Clear</Typography>
                </Button>
              </Grid>
            </Card>        
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Card className={classes.mainCard_3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className={classes.typoTitle2}>
                      {this.state.lottoTurn} {' 회차 예상번호'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                { this.state.result
                ?
                Object.keys(this.state.result).map((index) => {
                  return (
                    <Feed_Home_Fath key={index} 
                      indexNum = {index}
                      lottoNum={this.state.result[index]} />
                  );
                })
                :
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  {/*<CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />*/}
                </Grid>
                }
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>
          <Typography className={classes.typoTitle_5}>다음 예측 번호를 분석중입니다.</Typography>
          <Typography className={classes.typoTitle_5}>잠시만 기다려 주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_6}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2}>
        <DialogTitle>
          <Typography className={classes.typoTitle_5}>생일을 입력해주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_6}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_3}>
        <DialogTitle>
          <Typography className={classes.typoTitle_5}>개인정보 수집 및 활용동의(필수)에 체크해주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_6}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

Home_Fath = withStyles(styles, {withTheme: true})(Home_Fath)
export default connect(mapStateToProps, mapDispatchToProps)(Home_Fath)