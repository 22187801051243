import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Feed_Home_Up from './Feed_Home_Up';
import Feed_Home from './Feed_Home';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

//import './UploadButton.scss' // UploadPhoto 버튼 관련

import axios from 'axios';
const apiURL = "https://api.lottodl.com";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(5),
    marginBottom : theme.spacing(12),
  },
  mainCard_1: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width : '100%',
      // maxWidth: 650,
      // height: 450,
      maxHeight: 120,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  mainCard_2: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 400,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_3: {
    // background: '#abbdff',
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_4: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 600,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  imageText: {
    fontSize: 100,
    color: '#FFFAFA', // snow
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '140px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '200px',
    },
    left: 0,
    width: '100%',
  },
  mainMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    //width : '100%',
    //height : 400,
    objectFit: 'fill'
  },
  cardFrame: {
    width : '100%',
    // maxWidth: 650,
    // height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  typoTitle1_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle1_2: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle2: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_3: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
  },
  typoTitle_4: {
    fontSize : 17,
  },
  typoTitle_5: {
    fontSize : 17,
  },
  typoTitle_6: {
    fontSize : 17,
  },
  button1: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button2: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#A0A0A0',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  dialogButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class HomeApp extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        alarmDialogOpen_1: false,
        completed : 0,
        last_lotto_0 : '',
        last_lotto_1 : '',
        last_lotto_2 : '',
        last_lotto_3 : '',
        last_lotto_4 : '',
        last_lotto_5 : '',
        last_lotto_6 : '',
        last_lotto_7 : '',
        last_lotto_8 : '',
        last_lotto_9 : '',
        last_lotto_10 : '',
        lottoTurn : '', 
        lottoNum_00 : [],
        lottoNum_11 : [],
        lottoNum_22 : [],
        lottoNum_0 : [],
        lottoNum_1 : [],
        lottoNum_2 : [],
        lottoNum_3 : [],
        lottoNum_4 : [],
        //lottoNum_3 : [],
        //lottoNum_4 : [],
        alreadyGetLottoNum: false,
        no_lottolists : '',
        alarmDialogOpen_1: false,
      };
  }
  
  /*
  // getData from flask with axios
  getLottoNum() {
    axios.get(`${apiURL}`)
      .then(response => {
        const data = response.data;
        //console.log("data: ", data);
        this.setState({ lottoNum: data });
        //console.log("lottoNum: ", this.state.lottoNum);
        // Do something with the data...
      })
      .catch(error => {
        console.error(error);
      });
  }
  */

  // getData from flask with axios
  getLottoNumJason() {
    axios.get(`${apiURL}/getlotto`)
      .then(response => {
        const data = response.data;
        //console.log("JsonData: ", data);
        //console.log("lottoturn: ", data.lottoturn);
        // console.log("lottolists: ", data.lottolists);
        
        const last_lottoArray = data.last_lotto;
        const lottoArray = data.lottolists;

        //console.log("last_lottoArray: ", last_lottoArray)
        //console.log("lottoArray: ", lottoArray)

        if (lottoArray == 'No lottolists') {
          this.setState({no_lottolists: 'no_lottolists'})
        }

        /*
        console.log("lottoArray: ", lottoArray)
        console.log("lottoArray[0]: ", lottoArray[0])
        console.log("lottoArray[0][0]: ", lottoArray[0][0])
        console.log("lottoArray[0][1]: ", lottoArray[0][1])
        console.log("lottoArray[0][2]: ", lottoArray[0][2])
        console.log("lottoArray[0][3]: ", lottoArray[0][3])
        console.log("lottoArray[0][4]: ", lottoArray[0][4])
        console.log("lottoArray[0][5]: ", lottoArray[0][5])
        */

        const last_lottoArray_8 = this.numberWithCommas(last_lottoArray[8]);
        const last_lottoArray_9 = this.numberWithCommas(last_lottoArray[9]);
        const last_lottoArray_10 = this.numberWithCommas(last_lottoArray[10]);

        this.setState({ last_lotto_0: last_lottoArray[0], last_lotto_1: last_lottoArray[1], last_lotto_2: last_lottoArray[2],
          last_lotto_3: last_lottoArray[3], last_lotto_4: last_lottoArray[4], last_lotto_5: last_lottoArray[5],
          last_lotto_6: last_lottoArray[6], last_lotto_7: last_lottoArray[7], last_lotto_8: last_lottoArray_8,
          last_lotto_9: last_lottoArray_9, last_lotto_10: last_lottoArray_10,
        });

        try {
          if (data.lottoturn) {
            const localSavedTurn = localStorage.getItem("savedTurn")

            if (localSavedTurn == data.lottoturn) {
              localStorage.setItem("alreadyGetLottoNum", "true");
              this.setState({alreadyGetLottoNum: true})

              const localSavedLottoNum_0 = localStorage.getItem("savedLottoNum_0")
              const localSavedLottoNum_1 = localStorage.getItem("savedLottoNum_1")
              const localSavedLottoNum_2 = localStorage.getItem("savedLottoNum_2")
              const localSavedLottoNum_3 = localStorage.getItem("savedLottoNum_3")
              const localSavedLottoNum_4 = localStorage.getItem("savedLottoNum_4")

              const conSavedLottoNum_0 = localSavedLottoNum_0.split(',').map(Number);
              const conSavedLottoNum_1 = localSavedLottoNum_1.split(',').map(Number);
              const conSavedLottoNum_2 = localSavedLottoNum_2.split(',').map(Number);
              const conSavedLottoNum_3 = localSavedLottoNum_3.split(',').map(Number);
              const conSavedLottoNum_4 = localSavedLottoNum_4.split(',').map(Number);

              this.setState({ lottoTurn: localSavedTurn, lottoNum_0: conSavedLottoNum_0, lottoNum_1: conSavedLottoNum_1,
                lottoNum_2: conSavedLottoNum_2, lottoNum_3: conSavedLottoNum_3, lottoNum_4: conSavedLottoNum_4});
            }
            if (localSavedTurn != data.lottoturn) {
              localStorage.setItem("alreadyGetLottoNum", "false");
              this.setState({alreadyGetLottoNum: false})

              this.setState({lottoNum_0: [], lottoNum_1: [], lottoNum_2: [], lottoNum_3: [], lottoNum_4: []})
              this.setState({ lottoTurn: data.lottoturn, lottoNum_00: lottoArray[0], lottoNum_11: lottoArray[1],
                lottoNum_22: lottoArray[2], lottoNum_33: lottoArray[3], lottoNum_44: lottoArray[4]});
            }
          }
        } catch(e) {
          if (data.lottoturn) {
            localStorage.setItem("alreadyGetLottoNum", "false");
            this.setState({alreadyGetLottoNum: false})

            this.setState({lottoNum_0: [], lottoNum_1: [], lottoNum_2: [], lottoNum_3: [], lottoNum_4: []})
            this.setState({ lottoTurn: data.lottoturn, lottoNum_00: lottoArray[0], lottoNum_11: lottoArray[1],
              lottoNum_22: lottoArray[2], lottoNum_33: lottoArray[3], lottoNum_44: lottoArray[4]});
          }
          // console.log("savedTurn error")
        }

        /*
        this.setState({ lottoTurn: data.lottoturn, lottoNum_0: lottoArray[0], lottoNum_1: lottoArray[1],
           lottoNum_2: lottoArray[2]});
        */
        // this.setState({lottoNum_3: lottoArray[3], lottoNum_4: lottoArray[4],});
        // console.log("last_lotto: ", this.state.last_lotto);
        //console.log("lottoNum_0: ", this.state.lottoNum_0);
      })
      .catch(error => {
        console.error(error);
      });
  }
  
  /*
  getLottoNum() {
    fetch(`${apiURL}/validate?textID=${this.props.match.params.textID}`).then(res => {
        if(res.status != 200) {
            throw new Error(res.statusText);
        }
        return res.json();
    }).then(data => {
        if(data['result'] == true) {
            this.setState({imageUrl: apiURL + "/outputs?textID=" + this.props.match.params.textID})
        } else {
            this.setState({imageUrl: 'NONE'});
        }
    });
  }
  */
  /*
  // postData to Flask
  postData = (wordCloud) => {
    return fetch(`${apiURL}/process`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(wordCloud)
    }).then(res => {
        if(res.status != 200) {
            throw new Error(res.statusText);
        }
        return res.json();
    }).then(data => {
        this.setState({imageUrl: apiURL + "/outputs?textID=" + this.props.match.params.textID})
    });
  }
  */

  numberWithCommas = (number) => {
    const numberString = number.toString();
    const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
  };

  handleGetLotto = async event => {
    event.preventDefault();

    if (this.state.no_lottolists == 'no_lottolists') {
      this.setState({alarmDialogOpen_1 : true})
    }

    // lottoTrun 정보 있으면 실행
    if (this.state.lottoTurn) {
      try {
        const localAlreadyGetLottoNum = localStorage.getItem("alreadyGetLottoNum")

        if (localAlreadyGetLottoNum == "true") {
          this.setState({alreadyGetLottoNum: true})
        }
        if (localAlreadyGetLottoNum == "false") {  
          localStorage.setItem("savedTurn", this.state.lottoTurn);
          localStorage.setItem("savedLottoNum_0", this.state.lottoNum_00);
          localStorage.setItem("savedLottoNum_1", this.state.lottoNum_11);
          localStorage.setItem("savedLottoNum_2", this.state.lottoNum_22);
          localStorage.setItem("savedLottoNum_3", this.state.lottoNum_33);
          localStorage.setItem("savedLottoNum_4", this.state.lottoNum_44);

          this.setState({ lottoTurn: this.state.lottoTurn, lottoNum_0: this.state.lottoNum_00, lottoNum_1: this.state.lottoNum_11,
            lottoNum_2: this.state.lottoNum_22, lottoNum_3: this.state.lottoNum_33, lottoNum_4: this.state.lottoNum_44});
          
          localStorage.setItem("alreadyGetLottoNum", "true");
          this.setState({alreadyGetLottoNum: true})
        }
      } catch(e){
        console.log("alreadyGetLottoNum error")
      }
      
    }

    // 네트워크 연결 안되어서 정보 못받아 오면 버튼 눌러도 그냥 false 처리
    if (!this.state.lottoTurn) {
      this.setState({alreadyGetLottoNum: false})
    } 
    
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {

    //await this.getLottoNum();

    await this.getLottoNumJason();

    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }

    // 닫기 창을 눌렀을때 실행되는 함수
    handleClose = () => {
      this.setState({
        alarmDialogOpen_1: false,
      })
    }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, } = this.props;

    /*
    if(this.state.alreadyGetLottoNum) {
      this.getLottoNumJason()();
    }
    */

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card className={classes.mainCard_1}>
                <CardMedia
                  className={classes.mainMedia}
                  // image={'/images/main/bg_home.jpg'}  // 테스트시 사용
                  image={'/images/main/bg_deeplearning.png'}  // 테스트시 사용
                  // image={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                />
              </Card>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Card className={classes.mainCard_2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> 
                <Typography className={classes.typoTitle1_1}>
                  {this.state.last_lotto_0} {' 회차 당첨번호'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> 
                <Feed_Home_Up
                  last_lotto_1={this.state.last_lotto_1}
                  last_lotto_2={this.state.last_lotto_2}
                  last_lotto_3={this.state.last_lotto_3}
                  last_lotto_4={this.state.last_lotto_4}
                  last_lotto_5={this.state.last_lotto_5}
                  last_lotto_6={this.state.last_lotto_6}
                  last_lotto_7={this.state.last_lotto_7}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className={classes.typoTitle1_2}>
                    {'1등 당첨금 : '}{this.state.last_lotto_8}{'원'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className={classes.typoTitle1_2}>
                    {'2등 당첨금 : '}{this.state.last_lotto_9}{'원'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className={classes.typoTitle1_2}>
                    {'3등 당첨금 : '}{this.state.last_lotto_10}{'원'}
                  </Typography>
                </Grid>
              </Grid>
            </Card>        
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Card className={classes.mainCard_3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {this.state.no_lottolists == 'no_lottolists'
                    ?
                    <Typography className={classes.typoTitle2}>
                      다음 예측 번호를 분석중입니다...
                    </Typography>
                    :
                    <Typography className={classes.typoTitle2}>
                      {this.state.lottoTurn} {' 회차 예상번호'}
                    </Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNum_0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNum_1}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNum_2}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNum_3}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Feed_Home
                  lottoNum={this.state.lottoNum_4}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                {this.state.alreadyGetLottoNum
                ?
                <Button variant="contained" /*color="primary"*/ className={classes.button2}>
                  <Typography className={classes.typoTitle_4}>다음회차때 받기</Typography>
                </Button>
                :
                <Button variant="contained" /*color="primary"*/ className={classes.button1}
                onClick={this.handleGetLotto}>
                  <Typography className={classes.typoTitle_4}>무료 로또 예측번호 받기</Typography>
                </Button>
                }
              </Grid>
            </Card>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={0}>
            <Card className={classes.mainCard_4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
                <Typography className={classes.typoTitle_3}>
                  {'· 무료 버전: 일주일에 한번 5줄의 번호 분석'}
                </Typography>
                <Typography className={classes.typoTitle_3}>
                  {'· 매주 토요일 로또 추첨 이후 인공지능 딥러닝 분석 완료 후, 그다음 회차의 로또 예측 번호를 받으실 수 있습니다.'}
                </Typography>
              </Grid>
              <Divider/>
            </Card>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={0}>
            <Card className={classes.mainCard_4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
              </Grid>
              <Divider/>
            </Card>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>카이스컴퍼니</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>사업자등록번호 : 229-34-00829</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>대표 : 김재영</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>고객센터</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>043)714-3378</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>lootohub@gmail.com</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent3}>평일 09:00 ~ 18:00 / 주말·공휴일 휴무</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>
          <Typography className={classes.typoTitle_5}>다음 예측 번호를 분석중입니다.</Typography>
          <Typography className={classes.typoTitle_5}>잠시만 기다려 주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_6}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

HomeApp = withStyles(styles, {withTheme: true})(HomeApp)
export default connect(mapStateToProps, mapDispatchToProps)(HomeApp)