import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { TextField } from "@material-ui/core";
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import moment from 'moment';

//import './UploadButton.scss' // UploadPhoto 버튼 관련

import axios from 'axios';
const apiURL = "https://api.lottodl.com";


const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(5),
  },
  mainCard_1: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 600,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_2: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width : '100%',
      // maxWidth: 650,
      // height: 450,
      maxHeight: 600,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  mainCard_3: {
    // background: '#abbdff',
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    [theme.breakpoints.up('xs')]: {
      maxHeight: 300,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 205,
      maxHeight: 300,
    },
    //minHeight: 205,
    //maxHeight: 300,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_4: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 600,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard_5: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 300,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  imageText: {
    fontSize: 100,
    color: '#FFFAFA', // snow
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '140px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '200px',
    },
    left: 0,
    width: '100%',
  },
  mainMedia: {
    //height: 0,
    //paddingTop: '56.25%', // 16:9,
    width : '100%',
    height : 200,
    //objectFit: 'fill'
  },
  cardFrame: {
    width : '100%',
    // maxWidth: 650,
    // height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  typoTitle_1_1: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_1_2: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      color : '#FF0000',  // red
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_1_3: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_1_4: {
    [theme.breakpoints.down('xs')]: {
      color : '#FF0000',  // red
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  typoTitle_2_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(5),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_2_2: {
    color : '#FF0000',  // red
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft : theme.spacing(5),
      marginRight : theme.spacing(0),
    },
  },
  typoTitle_3: {
    fontSize : 20,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(2),
    marginRight : theme.spacing(2),
  },
  typoTitle_4: {
    fontSize : 20,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
  },
  typoTitle_5: {
    fontSize : 16,
    color: '#0c93d0',
  },
  typoTitle_6: {
    fontSize : 17,
  },
  typoTitle_7: {
    fontSize : 17,
  },
  typoTitle_8: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 18,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(2),
      marginRight : theme.spacing(2),
    },
  },
  button1: {
    width : '80%',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
    marginBottom: theme.spacing(1),
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button2: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#A0A0A0',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#A0A0A0',
      borderColor: '#A0A0A0',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  textField : {
    width: '100%',
    border: '1px solid light-grey',
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
  },
  textFieldSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  select_1: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  buttonChecked: {
    color: '#0c93d0',
    '&$checked': {
      color: '#0c93d0',
    },
  },
  dialogButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class Membership extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        alreadyRequestMembership: false,
        phoneNumber_1: '10',
        phoneNumber_2: '',
        phoneNumber_3: '',
        checked_1: true,
        alarmDialogOpen_1: false,
        alarmDialogOpen_2: false,
        alarmDialogOpen_3: false,
        completed : 0,
      };
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;
    
    try {
      // 저장된 날짜 이후 7일 확인. 1일 이후에 다시 맴버쉽 버튼 누를 수 있게 할 거임.
      const localTodayString = localStorage.getItem("today");
      const localToday = moment(localTodayString);
      const sevenDaysLater = moment(localToday).add(1, 'days');

      if (moment().isAfter(sevenDaysLater)) {
        localStorage.setItem("alreadyRequestMembership", "false");
      }
    } catch(e) {
      console.log(e)
    }
  
    try {
      const localAlreadyRequestMembership = localStorage.getItem("alreadyRequestMembership")
      if (localAlreadyRequestMembership == 'true') {
        this.setState({alreadyRequestMembership : true})
      }
    } catch(e) {
      console.log(e)
    }

    try {
      const localAlreadyRequestMembership = localStorage.getItem("alreadyRequestMembership")
      if (localAlreadyRequestMembership == 'false') {
        this.setState({alreadyRequestMembership : false})
      }
    } catch(e) {
      console.log(e)
    }

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  handleMembership = async event => {
    // 작성 안한 파트 작성하게끔 해주는 코드
    if (!this.state.phoneNumber_1) {
      this.setState({alarmDialogOpen_1: true});
      return;
    }
    if (!this.state.phoneNumber_2) {
      this.setState({alarmDialogOpen_1: true});
      return;
    }
    if (!this.state.phoneNumber_3) {
      this.setState({alarmDialogOpen_1: true});
      return;
    }
    if (!this.state.checked_1) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }

    let phoneNumber_1_0 = this.state.phoneNumber_1
    const phoneNumber_1_0_str = phoneNumber_1_0.toString(10);
    phoneNumber_1_0 = '0' + phoneNumber_1_0_str;

    const customerphonenumber = phoneNumber_1_0 + this.state.phoneNumber_2 + this.state.phoneNumber_3
    // console.log("customerphonenumber: ", customerphonenumber)

    const data = {
      phonenumber: customerphonenumber
    };
    
    /*
    console.log("phoneNumber_1_0: ", phoneNumber_1_0)
    console.log("this.state.phoneNumber_1: ", this.state.phoneNumber_1)
    console.log("this.state.phoneNumber_2: ", this.state.phoneNumber_2)
    console.log("this.state.phoneNumber_3: ", this.state.phoneNumber_3)
    console.log("this.state.checked_1: ", this.state.checked_1)
    */

    // 서버로 post
    axios.post(`${apiURL}/postmembership`, data)
    .then(response => {
      console.log(response);
      this.setState({phoneNumber_1: '10'});
      this.setState({phoneNumber_2: ''});
      this.setState({phoneNumber_3: ''});
      this.setState({checked_1: true});
      this.setState({alarmDialogOpen_3: true});

      localStorage.setItem("alreadyRequestMembership", "true");
      this.setState({alreadyRequestMembership: true})
      
      // 현재 시간 저장
      const today = moment();
      const todayString = today.format();
      // Save the todayString in the localStorage
      localStorage.setItem("today", todayString);
    })
    .catch(error => {
      console.log(error);
    });
  }

  handleAlreadyMembership= async event => {
    this.setState({phoneNumber_1: '10'});
    this.setState({phoneNumber_2: ''});
    this.setState({phoneNumber_3: ''});
    this.setState({checked_1: true});
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handleNumberChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    // The regular expression /[^0-9]/g will replace any non-numeric character with an empty string

    const numericValue_string = numericValue.toString();
    const numericValue_substring = numericValue_string.substring(0, 8);
    e.target.value = numericValue_substring; // Set the input value to the numeric value

    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  handleChange = (event) => {
    this.setState({ phoneNumber_1: event.target.value });
  };

  handleCheckedChange = name => event => {
    this.setState({ [name] : event.target.checked });
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
      alarmDialogOpen_5: false,
    })
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, } = this.props;

    /*
    if(this.state.alreadyGetLottoNum) {
      this.getLottoNumJason()();
    }
    */

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={0}>
            <Card className={classes.mainCard_1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
                <Typography className={classes.typoTitle_1_1} display="inline">
                  {'· 로또딥러닝은'}
                </Typography>
                <Typography className={classes.typoTitle_1_2} display="inline">
                  {'인공지능 딥러닝 알고리즘'}
                </Typography>
                <Typography className={classes.typoTitle_1_3} display="inline">
                  {'을 이용하여 다음 회차의 로또번호를 예측합니다.'}
                </Typography>
                <Typography className={classes.typoTitle_1_4} display="inline">
                  {'인공지능 딥러닝 알고리즘 예측 시스템'}
                </Typography>
              </Grid>
              <Divider/>
            </Card>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} align="flex-start">
              <Card className={classes.mainCard_2}>
                {/*
                <CardMedia
                  className={classes.mainMedia}
                  // image={'/images/main/bg_home.jpg'}  // 테스트시 사용
                  image={'/images/main/lotto_membership.png'}  // 테스트시 사용
                  // image={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                />
                */}
                <img
                  className={classes.mainMedia}
                  src={'/images/main/lotto_membership.png'}  // 테스트시 사용
                  // src={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                  // src={this.state.attachmentURL_1}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} align="flex-start">
              <Card className={classes.mainCard_3}>
                <Typography className={classes.typoTitle_2_1}>
                   {'· 맴버쉽 혜택'}
                </Typography>
                <Typography className={classes.typoTitle_2_2}>
                   {'1년 / 100,000원 / 매주 20조합'}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
              <List>
                <Divider/>
                <ListItem>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
                    <Typography className={classes.typoTitle_8}>{'<핸드폰 번호 입력>'}</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={3}>
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
              <Card className={classes.mainCard_5}>
                <FormControl fullWidth>
                  {/*<InputLabel id="select-label">phoneNumber_1</InputLabel>*/}
                  <Select
                    className={classes.select_1}
                    /*labelId="select-label"*/
                    id="select"
                    value={this.state.phoneNumber_1}
                    label="PhoneNumber_1"
                    onChange={this.handleChange}
                  >
                    <MenuItem value={10}>010</MenuItem>
                    <MenuItem value={11}>011</MenuItem>
                    <MenuItem value={16}>016</MenuItem>
                    <MenuItem value={17}>017</MenuItem>
                    <MenuItem value={18}>018</MenuItem>
                    <MenuItem value={19}>019</MenuItem>
                    <MenuItem value={17}>017</MenuItem>
                  </Select>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align="center">
              <Typography className={classes.typoTitle_4}>
                -
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
              <Card className={classes.mainCard_5}>
                <TextField
                  className={classes.textField}
                  id="phoneNumber_2"
                  name="phoneNumber_2"
                  //type="number"
                  autoFocus
                  value={this.state.phoneNumber_2}
                  onChange={this.handleNumberChange}
                  //placeholder="숫자만 입력 바랍니다."
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      input: classes.textFieldSize,
                    },
                  }}
                />
             </Card>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align="center">
              <Typography className={classes.typoTitle_4}>
                -
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2} align="flex-start">
              <Card className={classes.mainCard_5}>
                <TextField
                  className={classes.textField}
                  id="phoneNumber_3"
                  name="phoneNumber_3"
                  //type="number"
                  autoFocus
                  value={this.state.phoneNumber_3}
                  onChange={this.handleNumberChange}
                  //placeholder="숫자만 입력 바랍니다."
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      input: classes.textFieldSize,
                    },
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} align="center">
              {this.state.alreadyRequestMembership
                ?
                <Button variant="contained" /*color="primary"*/ className={classes.button2}
                onClick={this.handleAlreadyMembership}>
                  <Typography className={classes.typoTitle_7}>맴버쉽 신청완료</Typography>
                </Button>
                :
                <Button variant="contained" /*color="primary"*/ className={classes.button1}
                onClick={this.handleMembership}>
                  <Typography className={classes.typoTitle_7}>맴버쉽 신청하기</Typography>
                </Button>
                }
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="flex-start" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="flex-start">
              <FormControlLabel
                control={
                  <Checkbox color="default" className={classes.buttonChecked} checked={this.state.checked_1} 
                  onChange={this.handleCheckedChange('checked_1')} name="necessary" />
                }
                label="개인정보 수집 및 활용동의(필수)"
              /> 
              <Link component={RouterLink} to={`/infoagreememnt`} underline='none' color='inherit'>
                <Typography className={classes.typoTitle_5} display="inline">
                  [보기]
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>
          <Typography className={classes.typoTitle_6}>핸드폰 번호를 입력해주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_7}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2}>
        <DialogTitle>
          <Typography className={classes.typoTitle_6}>개인정보 수집 및 활용동의(필수)에 체크해주세요.</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_7}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_3}>
        <DialogTitle>
          <Typography className={classes.typoTitle_6}>맴버쉽 요청 감사드립니다.</Typography>
          <Typography className={classes.typoTitle_6}>문자로 연락 드리겠습니다^^</Typography>
        </DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>
              <Typography className={classes.typoTitle_7}>확인</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

Membership = withStyles(styles, {withTheme: true})(Membership)
export default connect(mapStateToProps, mapDispatchToProps)(Membership)