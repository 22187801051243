import React from 'react';
import { Router, Route, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import SVGIcon from './SVGIcon'
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';

// 라우터 관련
// 'react-redux'라이브러리에서 connect 변수 import. 
// 단 여기서 connect 변수는 export 시에 default로 하지 않았기 때문에(export default connect가 아니기 때문에) -> { } 가 붙는다
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
//import { Auth } from "aws-amplify";
//import { API } from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
//import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
//import * as uploadActions from 'redux/actions/upload'

// import { createBrowserHistory } from 'history';

//import './MainAppShell.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  menu: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginLeft: 18,
    marginRight: 18
  },
  TableHead: {
    fontSize: '1.0rem'
  },
  /*
  table: {
    minWidth : 1080
  },
  */
  progress : theme.spacing(2),
  menuButton: {
    marginRight: theme.spacing(5),
  },
  // xs 아래로 글자 사라진다.
  title_1: {
    flexGrow: 1,
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  // sm 이상 글자 보여준다.
  title_2: {
    flexGrow: 1,
    fontSize: 20,
    marginLeft : theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  login: {
    fontSize: 15,
  },
  searchBadge: {
    fontSize: 25,
  },
  notificationsIcon: {
    fontSize: 25,
    // color: '#0c93d0'
  },
  arrowBackIosIcon: {
    width: 23,
    height: 23
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    /*
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    */
  },
  searchIcon: {
    height: 10,
    width: 10,
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldBorder: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {  // 평소
      borderColor: "#0c93d0",
      borderWidth: 1,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {  // hover
      borderColor: "#0c93d0",
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {  // 글 작성시
      borderColor: "#0c93d0",
      borderWidth: 1,
    }
  },
  inputRoot: {
    fontSize: 15,
    width: '100%',
    color: 'inherit',
  },
  inputInput: {
    fontSize: 15,
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    /*
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    */
  },
  searchTypo: {
    fontSize: 15,
  },
  clearIcon: {
    height: 25,
    width: 25,
    paddingRight: 3,
  },
  AppShellText: {
    [theme.breakpoints.down('xs')]: {
      fontSize : 15,
      color: 'inherit',
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 15,
      color: 'inherit',
      padding: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    //background: "#0c93d0",
    boxShadow: "none",
  },
  toolBar: {
    height : 10,
  },
  appBar2: {
    marginTop: '47px',
    background: "#F5F5F5",
    //background: "#0c93d0",
  },
  toolBar2: {
    height : 15,
  },
  indicator: {
    backgroundColor: '#0c93d0',
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  content: {
    marginTop: '17px',
    flexGrow: 1,
    padding: theme.spacing(0),
    background: "#F8F8F8",
  },
  // toolbar: theme.mixins.toolbar,
  toolbar: {
    minHeight: 48,
  },
  hideIndicator: {
    height: 0
  },
})

class MainAppShellFieldApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          searchKeyword: '',
          isSearchToggle: false,  // 검색창 나오에 하기 위한 토글
          tabLinkValue: 0,
          currentTabLinkValue: 0, // localStorge를 위한 부가 state
          tabindicator: true,
          userId: '',
          currentPageTitle: '',
          currentPage: '',
        };
    }

    // render() 실행 후 동작. 뒤로 back 할때 필요
    componentDidUpdate() {
      this.updatechange();
    }

    // render() 실행 전 동작. 새로 고침시 이 함수 먼저 실행 
    componentWillMount() {
      this.mountchange();
    }

    updatechange = () => {
      if(this.props.history.location.pathname === `/home`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 0,
            tabindicator: true        
          });
        }
      }
      /*
      else if(this.props.history.location.pathname === `/membership`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 1,
            tabindicator: true
          });
        } 
      }
      */
      /*
      else if(this.props.history.location.pathname === `/dhlotterysite`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 2,
            tabindicator: true
          });
        } 
      }
      */
    }

    mountchange = () => {
      if(this.props.history.location.pathname === `/home`) {
        this.setState({
          tabLinkValue: 0,
          tabindicator: true        
        });
      }
      /*
      else if(this.props.history.location.pathname === `/membership`) {
        this.setState({
          tabLinkValue: 1,
          tabindicator: true        
        });
      }
      */
      /*
      else if(this.props.history.location.pathname === `/dhlotterysite`) {
        this.setState({
          tabLinkValue: 2,
          tabindicator: true        
        });
      }
      */
    }

    // render() 실행 후 동작. 새로 고침시 render()함수 실행후 이 함수 실행
    async componentDidMount() {
      const localCurrentTapLink = localStorage.getItem('currentTapLink')
      
      this.setState({
        tabLinkValue: localCurrentTapLink,
        tabindicator: true        
      });

      this.mountchange();
    }

    handleValueChange = (e) => {
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }

    // tabs 클릭시 바뀌는 state값
    handleChange = (event, tabLinkValue, tabindicator) => {
      this.setState({ tabLinkValue, tabindicator : true });
      // this.props.history.push(tabLinkValue);

      // setState값은 바로 바로 안바뀌기 때문에 부득이하게 아래와 같이 코딩 
      this.state.currentTabLinkValue = tabLinkValue;

      if(this.state.currentTabLinkValue === 0) {
        localStorage.setItem('currentTapLink', '0');
      }
      /*
      if(this.state.currentTabLinkValue === 1) {
        localStorage.setItem('currentTapLink', '1');
      }
      */
      /*
      if(this.state.currentTabLinkValue === 2) {
        localStorage.setItem('currentTapLink', '2');
      }
      */
    };

    handleAppbarBack = () => {
      /*
      // 세션스토리지로 대체
      const { uploadsearch, searchtogglefalse } = this.props;
      uploadsearch('');
      searchtogglefalse();
      */
      this.setState({searchKeyword: ''})
      //sessionStorage.setItem("searchKeyword", '');
      
      this.setState({isSearchToggle: false})
      //sessionStorage.setItem("isSearchToggle", 'false');
    }

    handleSearchBtn = () => {
      const { searchKeyword } = this.state;
      const { /*uploadsearch,*/ searchkeywordtrue } = this.props;
      //sessionStorage.setItem("searchKeyword", searchKeyword);
      // uploadsearch(searchKeyword);
      searchkeywordtrue();
      // window.location.reload();
    }

    handleKeyPress = (e) => {  
      // key 13 = 엔터를 의미
      if(e.charCode === 13) {
        this.handleSearchBtn()
      }
    }

    handleSearchClear = () => {
      this.setState({searchKeyword: ''})
      //sessionStorage.setItem("searchKeyword", '');
    }

    // 뒤로 Back
    handleGoBack = () => {
      this.props.history.goBack();  
    }

    render() {
      const { classes, theme/*, isSearchToggle*/ } = this.props;
      //const { searchKeyword, isSearchToggle } = this.state;

      // tabindicator가 true 일때 tabindicator 나오는 코드
      const tabs_1 = (
        <div>
          <Tabs
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            classes={{indicator: classes.indicator}}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="딥러닝 분석" dir={theme.direction} to={`/home`} component={RouterLink} className={classes.AppShellText} underline='none'/>
            {/*<Tab label="맴버쉽" dir={theme.direction} to={`/membership`} component={RouterLink} className={classes.AppShellText} underline='none'/>*/}
            {/*<Tab label="동행복권 사이트" dir={theme.direction} to={`/dhlotterysite`} component={RouterLink} className={classes.AppShellText} underline='none'/>*/}
          </Tabs>
        </div>
      );

      // tabindicator가 false 일때 tabindicator 안나오는 코드
      const tabs_2 = (
        <div>
          <Tabs
            classes={{ indicator: classes.hideIndicator }}
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            // textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="딥러닝 분석" dir={theme.direction} to={`/home`} component={RouterLink} className={classes.AppShellText} underline='none'/>
            {/*<Tab label="맴버쉽" dir={theme.direction} to={`/membership`} component={RouterLink} className={classes.AppShellText} underline='none'/>*/}
            {/*<Tab label="동행복권 사이트" dir={theme.direction} to={`/dhlotterysite`} component={RouterLink} className={classes.AppShellText} underline='none'/>*/}
          </Tabs>
        </div>
      );

      return (
        <div>
          {/* 네비게이션 바 */}
          <div className={classes.root}>
            <CssBaseline />
            {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
            <AppBar position="fixed" color="default" className={classes.appBar}>
              <Toolbar className={classes.toolBar} variant="dense"> {/* variant="dense" -> toolbar 크기 조정 가능 */}
                <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={false} sm={false} md={1} lg={2} xl={2}>
                    {/*
                    <IconButton
                      edge="start"
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="back"
                      onClick={this.handleGoBack}
                    >
                      <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#000000" />
                    </IconButton>
                    */}
                  </Grid>
                  <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
                    <CardMedia
                      className={classes.logoMedia}
                      // image="/static/images/logo/app.svg"
                      // image="/images/logo/app.svg"
                      image="/images/logo/logo.png"
                      aria-label="Recipe" 
                    />
                  </Grid>
                  <Grid item xs={9} sm={9} md={7} lg={4} xl={4}>
                    <Typography className={classes.title_1} noWrap>
                      로또딥러닝 : 인공지능 딥러닝 분석 시스템
                    </Typography>
                    <Typography className={classes.title_2} noWrap>
                      로또딥러닝
                    </Typography>
                  </Grid>
                  <Grid item xs={false} sm={false} md={false} lg={3} xl={3}>
                    {/* Search 버튼
                    {this.state.tabLinkValue === 0
                    ?
                    <IconButton  
                      edge="start"
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="back"
                      onClick={this.handleSearch}  // 클릭시 Search 팝업창 나오게끔 하기
                    >
                      <SearchIcon className={classes.searchIcon}/>
                    </IconButton>
                    :
                    <div></div>
                    }
                  */}
                  </Grid>
                </Grid>
              </Toolbar>
              <Divider />
            </AppBar>
            <AppBar position="fixed" className={classes.appBar2} color="default">
              <Toolbar className={classes.toolBar2} variant="dense">
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {this.state.tabindicator ? tabs_1 : tabs_2}
                  </Grid>
                </Grid>
              </Toolbar>
              <Divider />
            </AppBar>
            {/*{console.log('First:' + this.state.value)}*/}
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {/*<div position="fixed" className={classes.appBar}>*/}
                {/* 실제 사용자가 선택한 링크 (App.js의 라우팅과 연결) */}
                {/*<div id="content" style={{margin: 'auto', marginTop: '5px'}}>*/}
                <div>
                  {React.cloneElement(this.props.children)}
                </div>
              {/*</div>*/}
            </main>
          </div>
        </div>
      );
    }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //isKlaytnLoggedIn: state.auth.isKlaytnLoggedIn,
  //isAuthenticated: state.auth.isAuthenticated,
  // currentUser: state.auth.currentUser,
  // searchKeyword: state.upload.searchKeyword,  // 실제 검색을 위한 버튼 클릭시
  // isSearchToggle: state.upload.isSearchToggle,  // 검색창 나오에 하기 위한 토글(현재 세션스토리지로 사용)
  //isSearchKeyword: state.upload.isSearchKeyword,
  //isdrawerToggle: state.upload.isdrawerToggle,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  //integrateWallet: (privateKey) => dispatch(authActions.integrateWallet(privateKey)),
  //removeWallet: () => dispatch(authActions.removeWallet()),
  //userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  //userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  // uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  // searchtoggletrue: () => dispatch(uploadActions.searchtoggletrue()),
  // searchtogglefalse: () => dispatch(uploadActions.searchtogglefalse()),
  //searchkeywordtrue: () => dispatch(uploadActions.searchkeywordtrue()),
  //searchkeywordfalse: () => dispatch(uploadActions.searchkeywordfalse()),
  //drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  //drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
})


MainAppShellFieldApp = withStyles(styles, {withTheme: true})(MainAppShellFieldApp);
MainAppShellFieldApp = withRouter(MainAppShellFieldApp);
export default connect(mapStateToProps, mapDispatchToProps)(MainAppShellFieldApp)