import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { TextField } from "@material-ui/core";
import moment from 'moment';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { connect } from 'react-redux'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  avatar1_10: {
    backgroundColor: '#FFCC00', // Yello
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 27,
      height: 27,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar11_20: {
    backgroundColor: '#87CEEB', // Sky blue
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 27,
      height: 27,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar21_30: {
    backgroundColor: '#FF0000', // Red
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 27,
      height: 27,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar31_40: {
    backgroundColor: '#A0A0A0', // Gray
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 27,
      height: 27,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar41_45: {
    backgroundColor: '#66CC33', // Light green
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 27,
      height: 27,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatarnumber: {
    [theme.breakpoints.up('xs')]: {
      fontSize : 17,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
    },
  },
  avatar_trans: {
    backgroundColor: 'transparent',
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
      width: 20,
      height: 20,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
      width: 20,
      height: 20,
    },
  },
  typoPlus: {
    [theme.breakpoints.up('xs')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 30,
    },
    color : '#000000',  // Black
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  typoTitle1: {
    fontSize : 20,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
});

class Feed_Home_Up extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  async componentDidMount() {

  }

  render() {
    const {classes, last_lotto_1, last_lotto_2, last_lotto_3, last_lotto_4, last_lotto_5, last_lotto_6, last_lotto_7} = this.props;

    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          {/* 첫번째 */}
          {1 <= last_lotto_1 && last_lotto_1 <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_1}
            </Typography>
          </Avatar>
          :
          11 <= last_lotto_1 && last_lotto_1 <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_1}
            </Typography>
          </Avatar>
          :
          21 <= last_lotto_1 && last_lotto_1 <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_1}
            </Typography>
          </Avatar>
          :
          31 <= last_lotto_1 && last_lotto_1 <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_1}
            </Typography>
          </Avatar>
          :
          41 <= last_lotto_1 && last_lotto_1 <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_1}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_1}
            </Typography>
          </Avatar>
          }
          {/* 두번째 */}
          {1 <= last_lotto_2 && last_lotto_2 <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_2}
            </Typography>
          </Avatar>
          :
          11 <= last_lotto_2 && last_lotto_2 <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_2}
            </Typography>
          </Avatar>
          :
          21 <= last_lotto_2 && last_lotto_2 <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_2}
            </Typography>
          </Avatar>
          :
          31 <= last_lotto_2 && last_lotto_2 <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_2}
            </Typography>
          </Avatar>
          :
          41 <= last_lotto_2 && last_lotto_2 <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_2}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_2}
            </Typography>
          </Avatar>
          }
          {/* 세번째 */}
          {1 <= last_lotto_3 && last_lotto_3 <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_3}
            </Typography>
          </Avatar>
          :
          11 <= last_lotto_3 && last_lotto_3 <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_3}
            </Typography>
          </Avatar>
          :
          21 <= last_lotto_3 && last_lotto_3 <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_3}
            </Typography>
          </Avatar>
          :
          31 <= last_lotto_3 && last_lotto_3 <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_3}
            </Typography>
          </Avatar>
          :
          41 <= last_lotto_3 && last_lotto_3 <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_3}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_3}
            </Typography>
          </Avatar>
          }
          {/* 네번째 */}
          {1 <= last_lotto_4 && last_lotto_4 <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_4}
            </Typography>
          </Avatar>
          :
          11 <= last_lotto_4 && last_lotto_4 <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_4}
            </Typography>
          </Avatar>
          :
          21 <= last_lotto_4 && last_lotto_4 <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_4}
            </Typography>
          </Avatar>
          :
          31 <= last_lotto_4 && last_lotto_4 <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_4}
            </Typography>
          </Avatar>
          :
          41 <= last_lotto_4 && last_lotto_4 <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_4}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_4}
            </Typography>
          </Avatar>
          }
          {/* 다섯번째 */}
          {1 <= last_lotto_5 && last_lotto_5 <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_5}
            </Typography>
          </Avatar>
          :
          11 <= last_lotto_5 && last_lotto_5 <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_5}
            </Typography>
          </Avatar>
          :
          21 <= last_lotto_5 && last_lotto_5 <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_5}
            </Typography>
          </Avatar>
          :
          31 <= last_lotto_5 && last_lotto_5 <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_5}
            </Typography>
          </Avatar>
          :
          41 <= last_lotto_5 && last_lotto_5 <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_5}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_5}
            </Typography>
          </Avatar>
          }
          {/* 여섯번째 */}
          {1 <= last_lotto_6 && last_lotto_6 <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_6}
            </Typography>
          </Avatar>
          :
          11 <= last_lotto_6 && last_lotto_6 <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_6}
            </Typography>
          </Avatar>
          :
          21 <= last_lotto_6 && last_lotto_6 <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_6}
            </Typography>
          </Avatar>
          :
          31 <= last_lotto_6 && last_lotto_6 <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_6}
            </Typography>
          </Avatar>
          :
          41 <= last_lotto_6 && last_lotto_6 <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_6}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_6}
            </Typography>
          </Avatar>
          }
          <Avatar className={classes.avatar_trans}>
            <Typography className={classes.typoPlus}>
              +
            </Typography>
          </Avatar>
          {/* 일곱번째 */}
          {1 <= last_lotto_7 && last_lotto_7 <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_7}
            </Typography>
          </Avatar>
          :
          11 <= last_lotto_7 && last_lotto_7 <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_7}
            </Typography>
          </Avatar>
          :
          21 <= last_lotto_7 && last_lotto_7 <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_7}
            </Typography>
          </Avatar>
          :
          31 <= last_lotto_7 && last_lotto_7 <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_7}
            </Typography>
          </Avatar>
          :
          41 <= last_lotto_7 && last_lotto_7 <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_7}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {last_lotto_7}
            </Typography>
          </Avatar>
          }
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

Feed_Home_Up = withStyles(styles, {withTheme: true})(Feed_Home_Up)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_Home_Up);