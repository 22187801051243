export const KLAYTNLOGIN = 'KLAYTNLOGIN'
export const KLAYTNLOGOUT = 'KLAYTNLOGOUT'
export const INTEGRATE_WALLET = 'INTEGRATE_WALLET'
export const REMOVE_WALLET = 'REMOVE_WALLET'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'

export const SET_FEED = 'SET_FEED'

//-----------------------------------------------------
// 밑에서 부터 내가 짠 코드
//-----------------------------------------------------

//export const AWSLOGIN = 'AWSLOGIN'
//export const AWSLOGOUT = 'AWSLOGOUT'
// export const AWSCOGNITOON = 'AWSCOGNITOON'
export const USERHASAUTHENTICATEDTRUE = 'USERHASAUTHENTICATEDTRUE'
export const USERHASAUTHENTICATEDFALSE = 'USERHASAUTHENTICATEDFALSE'
export const CURRENTAUTHENTICATEDUSER = 'CURRENTAUTHENTICATEDUSER'
export const SWREGISTRATION = 'SWREGISTRATION'

export const COMMENTLOADINGTRUE = 'COMMENTLOADINGTRUE'
export const COMMENTLOADINGFALSE = 'COMMENTLOADINGFALSE'
export const FAVORITELOADINGTRUE = 'FAVORITELOADINGTRUE'
export const FAVORITELOADINGFALSE = 'FAVORITELOADINGFALSE'
export const COMMFAVORITELOADINGTRUE = 'COMMFAVORITELOADINGTRUE'
export const COMMFAVORITELOADINGFALSE = 'COMMFAVORITELOADINGFALSE'

export const FOLLOWINGLOADINGTRUE = 'FOLLOWINGLOADINGTRUE'
export const FOLLOWINGLOADINGFALSE = 'FOLLOWINGLOADINGFALSE'

export const APPLYINGLOADINGTRUE = 'APPLYINGLOADINGTRUE'
export const APPLYINGLOADINGFALSE = 'APPLYINGLOADINGFALSE'

export const UPLOADCONNECTINGTRUE = 'UPLOADCONNECTINGTRUE'
export const UPLOADCONNECTINGFALSE = 'UPLOADCONNECTINGFALSE'

export const UPLOAD = 'UPLOAD'
export const NOTUPLOAD = 'NOTUPLOAD'
export const UPLOADCONTENTS = 'UPLOADCONTENTS'

export const UPLOADSEARCH = 'UPLOADSEARCH'
// 검색창 나오게끔 하는 검색 버튼 클릭시 (실제 검색결과 안나옴)
export const SEARCHTOGGLETRUE = 'SEARCHTOGGLETRUE'
export const SEARCHTOGGLEFALSE = 'SEARCHTOGGLEFALSE'
// 실제 검색 내용 나오게끔 하는 검색 버튼 클릭시 (실제 검색결과 나옴)
export const SEARCHKEYWORDTRUE = 'SEARCHKEYWORDTRUE'
export const SEARCHKEYWORDFALSE = 'SEARCHKEYWORDFALSE'

export const DRAWERTOGGLETRUE = 'DRAWERTOGGLETRUE'
export const DRAWERTOGGLEFALSE = 'DRAWERTOGGLEFALSE'

export const DEVICELISTLOADINGTRUE = 'DEVICELISTLOADINGTRUE'
export const DEVICELISTLOADINGFALSE = 'DEVICELISTLOADINGFALSE'

export const DEVICEHOMELOADINGTRUE = 'DEVICEHOMELOADINGTRUE'
export const DEVICEHOMELOADINGFALSE = 'DEVICEHOMELOADINGFALSE'

export const DEVICEDBCONNECTOADINGTRUE = 'DEVICEDBCONNECTOADINGTRUE'
export const DEVICEDBCONNECTLOADINGFALSE = 'DEVICEDBCONNECTLOADINGFALSE'