import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(7),
  },
  mainCard1: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 3000,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#FCB04C',
  },
  // cardmedia 사용시
  mainMedia1: {
    height: 0,
    paddingTop: '150%',
    // width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  kaissLogoMedia: {
    marginTop: 30,
    marginBottom: 20,
    width: 200,
    height: 60,
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class DhlotterySite extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        completed : 0,
      };
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {

  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, } = this.props;

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
              <Card className={classes.mainCard1}>
                {/*<Typography className={classes.imageText}>려려 안녕</Typography>*/}
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* sandbox는 팝업창 prevent 해준다 */}
                    <iframe title="naver" src="https://www.dhlottery.co.kr/common.do?method=main" width="100%" height="1000px" sandbox="allow-scripts"></iframe>
                  </Grid>
                </Grid>
              </Card>
            </Grid>             
          </Grid>
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

DhlotterySite = withStyles(styles, {withTheme: true})(DhlotterySite)
export default connect(mapStateToProps, mapDispatchToProps)(DhlotterySite)