import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { TextField } from "@material-ui/core";
import moment from 'moment';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { connect } from 'react-redux'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  avatar1_10: {
    backgroundColor: '#FFCC00', // Yello
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 30,
      height: 30,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar11_20: {
    backgroundColor: '#87CEEB', // Sky blue
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 30,
      height: 30,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar21_30: {
    backgroundColor: '#FF0000', // Red
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 30,
      height: 30,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar31_40: {
    backgroundColor: '#A0A0A0', // Gray
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 30,
      height: 30,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatar41_45: {
    backgroundColor: '#66CC33', // Light green
    //margin: 10,
    [theme.breakpoints.up('xs')]: {
      width: 30,
      height: 30,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
  },
  avatarnumber: {
    [theme.breakpoints.up('xs')]: {
      fontSize : 19,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
    },
  },
});

class Feed_Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  async componentDidMount() {

  }

  render() {
    const {classes, lottoNum} = this.props;

    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          {/* 첫번째 */}
          {1 <= lottoNum[0] && lottoNum[0] <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[0]}
            </Typography>
          </Avatar>
          :
          11 <= lottoNum[0] && lottoNum[0] <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[0]}
            </Typography>
          </Avatar>
          :
          21 <= lottoNum[0] && lottoNum[0] <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[0]}
            </Typography>
          </Avatar>
          :
          31 <= lottoNum[0] && lottoNum[0] <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[0]}
            </Typography>
          </Avatar>
          :
          41 <= lottoNum[0] && lottoNum[0] <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[0]}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[0]}
            </Typography>
          </Avatar>
          }
          {/* 두번째 */}
          {1 <= lottoNum[1] && lottoNum[1] <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[1]}
            </Typography>
          </Avatar>
          :
          11 <= lottoNum[1] && lottoNum[1] <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[1]}
            </Typography>
          </Avatar>
          :
          21 <= lottoNum[1] && lottoNum[1] <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[1]}
            </Typography>
          </Avatar>
          :
          31 <= lottoNum[1] && lottoNum[1] <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[1]}
            </Typography>
          </Avatar>
          :
          41 <= lottoNum[1] && lottoNum[1] <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[1]}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[1]}
            </Typography>
          </Avatar>
          }
          {/* 세번째 */}
          {1 <= lottoNum[2] && lottoNum[2] <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[2]}
            </Typography>
          </Avatar>
          :
          11 <= lottoNum[2] && lottoNum[2] <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[2]}
            </Typography>
          </Avatar>
          :
          21 <= lottoNum[2] && lottoNum[2] <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[2]}
            </Typography>
          </Avatar>
          :
          31 <= lottoNum[2] && lottoNum[2] <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[2]}
            </Typography>
          </Avatar>
          :
          41 <= lottoNum[2] && lottoNum[2] <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[2]}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[2]}
            </Typography>
          </Avatar>
          }
          {/* 네번째 */}
          {1 <= lottoNum[3] && lottoNum[3] <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[3]}
            </Typography>
          </Avatar>
          :
          11 <= lottoNum[3] && lottoNum[3] <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[3]}
            </Typography>
          </Avatar>
          :
          21 <= lottoNum[3] && lottoNum[3] <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[3]}
            </Typography>
          </Avatar>
          :
          31 <= lottoNum[3] && lottoNum[3] <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[3]}
            </Typography>
          </Avatar>
          :
          41 <= lottoNum[3] && lottoNum[3] <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[3]}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[3]}
            </Typography>
          </Avatar>
          }
          {/* 다섯번째 */}
          {1 <= lottoNum[4] && lottoNum[4] <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[4]}
            </Typography>
          </Avatar>
          :
          11 <= lottoNum[4] && lottoNum[4] <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[4]}
            </Typography>
          </Avatar>
          :
          21 <= lottoNum[4] && lottoNum[4] <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[4]}
            </Typography>
          </Avatar>
          :
          31 <= lottoNum[4] && lottoNum[4] <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[4]}
            </Typography>
          </Avatar>
          :
          41 <= lottoNum[4] && lottoNum[4] <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[4]}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[4]}
            </Typography>
          </Avatar>
          }
          {/* 여섯번째 */}
          {1 <= lottoNum[5] && lottoNum[5] <= 10
          ?
          <Avatar className={classes.avatar1_10}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[5]}
            </Typography>
          </Avatar>
          :
          11 <= lottoNum[5] && lottoNum[5] <= 20
          ?
          <Avatar className={classes.avatar11_20}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[5]}
            </Typography>
          </Avatar>
          :
          21 <= lottoNum[5] && lottoNum[5] <= 30
          ?
          <Avatar className={classes.avatar21_30}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[5]}
            </Typography>
          </Avatar>
          :
          31 <= lottoNum[5] && lottoNum[5] <= 40
          ?
          <Avatar className={classes.avatar31_40}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[5]}
            </Typography>
          </Avatar>
          :
          41 <= lottoNum[5] && lottoNum[5] <= 45
          ?
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[5]}
            </Typography>
          </Avatar>
          :
          <Avatar className={classes.avatar41_45}>
            <Typography className={classes.avatarnumber}>
              {lottoNum[5]}
            </Typography>
          </Avatar>
          }
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

Feed_Home = withStyles(styles, {withTheme: true})(Feed_Home)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_Home);